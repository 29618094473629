.button-component {
  width: 100%;
  height: $margin * 5;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: $hover-transition;
  border-radius: $margin * 2.5;

  p {
    @include p;
  }

  &.black {
    background-color: $black;

    p {
      color: $white;
    }
  }

  &.blue {
    background-color: $blue;

    p {
      color: $white;
    }
  }

  &.red {
    background-color: $red;

    p {
      color: $white;
    }
  }

  &.loading {
    opacity: 0.8;
  }

  &:hover {
    opacity: 0.90;
  }
}
