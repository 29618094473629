.left-sidebar-component {
  width: 91px;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: $margin * 3 0;
  background-color: $light-purple;

  &.authenticated {
    background-color: $black;

    img {
      width: 42px;
    }

    .logout-button {
      cursor: pointer;
      transition: $hover-transition;

      .logout-outer {
        width: 42px;
        height: 42px;
        box-sizing: border-box;
        border: 1px solid $light-gray;
        border-radius: 21px;
        background-color: $black;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .logout-inner {
          width: 4px;
          height: 18px;
          background: $light-gray;
          border-radius: 2px;
          position: relative;
          top: -1px;
        }
      }

      &:hover {
        opacity: 0.90;
      }
    }
  }
}
