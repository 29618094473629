.landing-page {
  background-color: $white;

  .header {
    @include row;
    height: $margin * 10;

    .header-left {
      @include col___gb(1);
    }

    .header-center {
      @include col___gb(10);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      a {
        color: $black;
        text-decoration: none;
        transition: opacity ease-in-out 150ms;

        &:hover {
          opacity: 0.8;
        }

        &.contact {
          @include big-p;
          margin-right: $margin * 6;
        }

        &.login {
          @include h2;
        }
      }
    }
  }

  .section {
    &.section-1 {
      position: relative;

      .section-background {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        .section-background-top {
          height: 40%;
          background: $white;
        }

        .section-background-bottom {
          height: 60%;
          background: linear-gradient(180deg, #F1F2F5 26.53%, rgba(241, 242, 245, 0) 93.52%);
        }
      }

      .section-content {
        @include col___gb(8);
        position: relative;
        margin: 0 auto;
        padding: $margin * 24 0;
        text-align: center;

        h1 {
          font-family: $font-mono;
          font-weight: bold;
          font-size: 90px;
          line-height: 108px;
          letter-spacing: -0.01em;
        }

        .screenshot-container {
          margin-top: $margin * 12;

          img {
            width: 100%;
          }
        }

        .translation-demo-container {
          margin-top: $margin * 19;

          .translation-demo-title {
            font-family: $font-mono;
            font-weight: normal;
            font-size: 11px;
            line-height: 13px;
          }

          .translation-demo-description {
            @include h1;
            margin-top: $margin * 4;
          }

          .translation-demo-selector-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-top: $margin * 5;

            .translation-demo-selector {
              width: 80px;
              height: 80px;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              border: 1px solid $black;
              transition: all ease-in-out 150ms;
              cursor: pointer;

              p {
                font-family: $font-mono;
                font-weight: bold;
                font-size: 18px;
                color: $black;
              }

              &:hover, &.selected {
                background-color: $black;

                p {
                  color: $white;
                }
              }
            }
          }

          .translation-demo-try-container {
            margin-top: $margin * 6;

            p {
              font-family: $font-mono;
              font-weight: normal;
              font-size: 11px;
              line-height: 13px;

              &:first-of-type {
                margin-bottom: $margin * 2;
              }
            }
          }
        }
      }
    }

    &.section-2 {
      display: flex;
      flex-direction: row;

      .section-2-section {
        @include row;
        width: 50%;
        height: 600px;

        .section-empty {
          @include col___gb(2);
        }

        .section-center {
          @include col___gb(8);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          .section-title {
            font-family: $font-mono;
            font-weight: normal;
            font-size: 11px;
            line-height: 13px;
            color: $white;
          }

          h2 {
            @include h1;
            color: $white;
            margin-top: $margin;
          }

          .middle-info {
            margin-top: $margin * 4;

            p {
              @include p;
              color: $white;
            }
          }

          .sdk-list {
            display: flex;
            flex-direction: row;
            margin-top: $margin * 4;

            a {
              color: $white;
              text-decoration: none;
            }

            p {
              @include p;
              color: $med-gray;

              &.divider {
                margin: 0 4px;
              }

              &.active {
                color: $white;
              }
            }
          }
        }

        &.left-section {
          background: $black;
        }

        &.right-section {
          background: $lighter-gray;

          .section-title, h2, p, .middle-info p {
            color: $black;
          }
        }
      }
    }
  }

  .footer {
    @include row;
    background-color: #444756;
    padding: $margin * 4 0;

    .empty-section {
      @include col___gb(1);
    }

    .logo-section {
      @include col___gb(3);
    }

    .center-info-section {
      @include col___gb(3);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      .social-media-links {
        display: flex;
        flex-direction: row;

        a, p {
          @include p;
          text-decoration: none;
          color: $white;
        }

        p {
          margin: 0 4px;
        }
      }

      .copyright {
        @include label;
        color: $white;
      }
    }

    .right-info-section {
      @include col___gb(4);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      .mailto-container {
        display: flex;
        flex-direction: column;

        a {
          @include p;
          text-decoration: none;
          color: $white;

          &:first-of-type {
            margin-bottom: $margin;
          }
        }
      }

      .all-rights {
        @include label;
        color: $white;
      }
    }
  }

  @include responsive(ipadPro) {
    .header {
      .header-center {
        a {
          &.contact {
            font-size: 14px;
            margin-right: $margin * 2;
          }

          &.login {
            font-size: 13px;
          }
        }
      }
    }

    .section {
      &.section-1 {
        .section-content {
          @include col___gb(10);
          padding: 96px 0;

          h1 {
            font-size: 40px;
            line-height: 140%;
          }

          .translation-demo-container {
            margin-top: 76px;

            .translation-demo-description {
              font-size: 25px;
              line-height: 110%;
            }

            .translation-demo-selector-container {
              .translation-demo-selector {
                width: 60px;
                height: 60px;
              }
            }
          }
        }
      }

      &.section-2 {
        display: block;

        .section-2-section {
          @include row;
          width: 100%;
          height: 400px;

          .section-empty {
            @include col___gb(1);
          }

          .section-center {
            @include col___gb(10);
          }
        }
      }
    }

    .footer {
      display: block;

      .empty-section {
        display: none;
      }

      .logo-section, .center-info-section, .right-info-section {
        @include col___gb(10);
        margin: 0 auto;
        margin-bottom: $margin * 4;
      }

      .center-info-section {
        .social-media-links {
          margin-bottom: $margin * 2;
        }
      }

      .right-info-section {
        .mailto-container {
          margin-bottom: $margin * 2;
        }
      }
    }
  }
}
    
