.right-sidebar-component {
  width: 91px;
  height: auto;
  background-color: $light-purple;
  display: flex;
  justify-content: center;
  padding-bottom: 54px;
  align-items: flex-end;

  .help-button {

    cursor: pointer;
    transition: $hover-transition;

    .help-outer {
      img {
        width: 20px;
        height: 20px;
        border-radius: 2px;
      }
    }
  }
}
