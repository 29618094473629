.text-field-component {
  @include p;
  width: 100%;
  height: $margin * 5;
  box-sizing: border-box;
  border: 1px solid $lighter-gray;
  border-radius: $margin * 2.5;
  padding: 0 $margin * 3;
  transition: $border-transition;
  background-color: $white;

  &.borderless {
    border: unset;
    background-color: unset;
  }

  &.error {
    color: $red;
    border-color: $red;

    &:focus {
      border-color: $red;
    }
  }

  &:focus {
    outline: none;
    border-color: $dark-gray;

    &::placeholder {
      color: $white;
    }
  }

  &::placeholder {
    color: $med-gray;
  }
}
