@font-face {
  font-family: 'GTAmerica-Regular';
  src: url('../assets/fonts/GTAmerica-Regular.eot');
  src: url('../assets/fonts/GTAmerica-Regular.woff2') format('woff2'),
       url('../assets/fonts/GTAmerica-Regular.woff') format('woff'),
       url('../assets/fonts/GTAmerica-Regular.svg#GTAmerica-Regular') format('svg'),
       url('../assets/fonts/GTAmerica-Regular.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GTAmerica-Bold';
  src: url('../assets/fonts/GTAmerica-Bold.eot');
  src: url('../assets/fonts/GTAmerica-Bold.woff2') format('woff2'),
       url('../assets/fonts/GTAmerica-Bold.woff') format('woff'),
       url('../assets/fonts/GTAmerica-Bold.svg#GTAmerica-Bold') format('svg'),
       url('../assets/fonts/GTAmerica-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GTAmerica-Light';
  src: url('../assets/fonts/GTAmerica-Light.eot');
  src: url('../assets/fonts/GTAmerica-Light.woff2') format('woff2'),
       url('../assets/fonts/GTAmerica-Light.woff') format('woff'),
       url('../assets/fonts/GTAmerica-Light.svg#GTAmerica-Light') format('svg'),
       url('../assets/fonts/GTAmerica-Light.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
