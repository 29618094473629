@import 'variables.scss';
@import 'resets.scss';
@import 'grid.scss';
@import 'fonts.scss';
@import 'text.scss';
@import 'utils.scss';
@import 'app.scss';

@import 'pages/styles.scss';

@import 'components/styles.scss';
