.user-component {
  display: flex;
  flex-direction: row;
  align-items: center;

  .user-image {
    border-radius: 50%;
    border: 1px solid $white;
    width: 32px;
    height: 32px;
    margin-right: $margin;
  }

  .user-initials {
    @extend .user-image;
    @include label;
    background-color: $lighter-gray;
    padding-bottom: 1px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $med-gray;
    text-transform: uppercase;
  }

  .user-name {
    @include p;
    height: 16px;
    line-height: 16px;
  }

  .user-email {
    @include label;
    height: 16px;
    line-height: 16px;
    color: $med-gray;
    margin-left: 20px;
  }

  .user-details {
    display: flex;
  }
}
