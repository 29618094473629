.app {
  @extend .row;

  .app-content {
    height: 100vh;
    flex: 1;
    flex-direction: row;
    max-width: 100vw;
    overflow-y: scroll;
    background-color: $white;
  }
}
