.footer-component {
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: $blue;

  .title {
    @include h1;
    text-transform: uppercase;
    color: $white;
  }

  .links {
    padding-top: 10px;
  }

  .sub-links {
    margin-top: 20px;

    .copryright {
      @include p;
      color: $white;
    }

    .link {
      margin-bottom: 10px;

      a {
        @include p;
        text-decoration: none;
        color: $white;
      }
    }
  }
}
