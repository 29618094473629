.project-settings-page {
  @include row;

  .project-translations-content {

    flex: 1;
    background-color: $light-purple;
    @keyframes fade-in {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @keyframes fade-out {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }


    .localized-string-value-submit-action {
      display: none;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      &.show {
        display: flex;
      }

      .submit-label {
        @include label;
        color: $med-gray;
        margin-right: $margin * 2;
      }

      &.disabled .button-submit-localized-string-values {
        cursor: not-allowed;
        .button-text {
          color: $med-gray;
        }
      }

      .button-submit-localized-string-values {
        cursor: pointer;
        width: 140px;
        height: 40px;
        padding-right: $margin * 2.5;
        background: $black;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.02);
        border-radius: 82px;
        box-sizing: border-box;

        .button-text {
          @include p;
          text-align: right;
          color: $white;
          line-height: 40px;
        }
      }
    }
  }
}
