.dropdown-component {
  width: 100%;
  position: relative;
  cursor: pointer;

  &.is-open .dropdown-control {
    border-color: $dark-gray;
  }

  &.is-open .dropdown-control .dropdown-arrow {
    border-top: unset;
    border-bottom: 5px solid #555;
  }

  .dropdown-control {
    height: $margin * 5;
    box-sizing: border-box;
    border: 1px solid $lighter-gray;
    border-radius: $margin * 2.5;
    padding: 0 $margin * 3;
    transition: $border-transition;
    background-color: $white;
    display: flex;
    align-items: center;

    &.disabled {
      opacity: 0.7
    }

    .dropdown-placeholder {
      @include p;
      padding: $margin 0;
      padding-left: 0;
      cursor: pointer;
      border-radius: 2px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .Dropdown-arrow-wrapper {
      padding: $margin 2 * $margin;
    }

    .dropdown-arrow {
      position: absolute;
      right: 16px;
      top: 18px;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #555;
    }
  }

  .dropdown-menu {
    @extend .polyglot-scrollbar;
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 2;
    
    div {
      @include p;
      cursor: pointer;
      background-color: $lighter-gray;
      padding: $margin 2 * $margin;
      border-radius: 2px;

      &:hover { background-color: $light-gray; }
    }
  }
}
