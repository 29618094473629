.dashboard-page {
  @include row;

  .left-section {
    @include col___gb(5);
    height: 100vh;
    padding: $margin * 4 $margin * 5;
    padding-right: 0;
    background-color: $light-purple;

    .project-empty {
      height: calc(100% - 98px);
      padding-right: $margin * 5;
      text-align: center;
      padding-top: $margin;

      .project-empty-title {
        @include h3;
      }
    }

    .project-list {
      @extend .polyglot-scrollbar;
      height: calc(100% - 98px);
      padding-right: $margin * 5;

      .project-list-item {
        width: 100%;
        height: $margin * 16;
        box-sizing: border-box;
        box-shadow: $box-shadow;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: $margin * 2;
        margin-bottom: $margin * 2;
        background-color: $white;
        cursor: pointer;

        .project-list-item-top-section {
          width: 100%;

          .project-list-item-name {
            @include h2;
            margin-bottom: $margin / 2;
          }

          .project-list-item-projects {
            @include label;
            color: $med-gray;
          }
        }

        .project-list-item-bottom-section {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .project-list-item-strings {
            .project-list-item-strings-label {
              @include label;
              color: $med-gray;
              margin-bottom: $margin / 2;
            }

            .project-list-item-strings-count {
              @include h3;
              line-height: 24px;
            }
          }

          .project-list-item-languages {
            @include label;
            text-align: right;
            color: $med-gray;

            p {
              margin-bottom: $margin / 2;
            }

            .project-list-item-languages-labels {
              @include row;
              justify-content: flex-end;
              margin-top: $margin * 0.5;
              min-height: 24px;

              .project-list-item-language {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 32px;
                height: 24px;
                margin-left: $margin / 2;
                box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.02);
                border-radius: 82px;
                background: $light-gray;

                &:first-of-type {
                  margin-left: 0;
                  background: $blue;

                  .project-list-item-language-label {
                    color: $white;
                  }
                }

                .project-list-item-language-label {
                  @include label;
                  color: $blue;
                  text-transform: uppercase;
                }
              }
            }
          }
        }

        &.selected {
          background-color: $light-gray;
        }
      }
    }

    .add-project {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-top: solid 1px $med-gray;
      padding-top: $margin * 4;
      margin-right: $margin * 5;

      button {
        @include label;
        background-color: $med-gray;
        color: $white;
        font-size: 30px;
        height: 46px;
        width: 46px;
        border-radius: 23px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding-bottom: 4px;

        &:hover {
          opacity: 0.8;
        }
      }

      p {
        @include label;
        color: $med-gray;
        margin-top: $margin;
      }
    }
  }

  .right-section {
    @include col___gb(7);
    height: 100vh;
    background-color: $light-purple;

    .create-project {
      @include row;
      padding-top: $margin * 4;

      .create-project-left-section, .create-project-right-section {
        @include col___gb(3);
      }

      .create-project-center-section {
        @include col___gb(6);

        .create-project-title {
          @include p;
          margin-bottom: $margin * 3;
        }

        .form {
          .field {
            margin-bottom: $margin * 2;
          }

          .form-error {
            height: 12px;
            margin-bottom: $margin * 3;
            padding: 0 ($margin * 3 + 1);

            p {
              @include label;
              color: $red;
            }
          }
        }
      }
    }

    .project-detail {
      @include row;
      height: 100%;
      padding: $margin * 4 $margin * 5;

      .project-detail-left-section {
        @include col___gb(8);

        .project-detail-overview {
          @include p;
        }
      }

      .project-detail-right-section {
        @include col___gb(4);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .project-detail-members {
          @include p;
          margin-bottom: $margin * 3;
          text-align: right;
        }

        .project-detail-member {
          margin-top: $margin * 1.25;
          padding-bottom: $margin;
          border-bottom: 1px solid $light-gray;

          &:first-of-type {
            margin-top: 0;
          }
        }

        .project-menu {
          text-align: right;

          .project-menu-option {
            height: 40px;
            cursor: pointer;

            &.link {
              text-decoration: none;
            }

            .project-menu-option-label {
              @include p;
              line-height: 40px;
              color: $dark-gray;
              border-bottom: 1px solid $light-gray;

              &:hover {
                opacity: 0.8;
              }

              &.warning {
                color: $red;
              }
            }
          }
        }
      }
    }
  }

  .right-sidebar {
    width: 32px;
    height: 100%;
    background-color: $light-purple;
    position: fixed;
    right: 0;
    top: 0;
  }

  &.right-section-open {
    .right-section {
      background-color: $lighter-gray;
    }

    .right-sidebar {
      background-color: $lighter-gray;
    }
  }
}
