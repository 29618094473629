$font-regular: "GTAmerica-Regular", "Helvetica Neue", sans-serif;
$font-bold: "GTAmerica-Bold", "Helvetica Neue", sans-serif;
$font-light: "GTAmerica-Light", "Helvetica Neue", sans-serif;
$font-mono: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;

$black: #000000;
$white: #ffffff;
$blue: #060E9F;
$red: #FF4A22;
$dark-gray: #313131;
$med-gray: #858AA4;
$light-gray: #DADCE8;
$lighter-gray: #F1F2F5;
$light-purple: #FBFCFD;

$margin: 8px;
$gutter: 24px;
$gutter-l: 24px;
$col-value : 8.333333%;

$container-l: 70.5em;
$container-m: 61em;
$container-ipad: 52em;

$breakpoint-xs: 320px;
$breakpoint-s: 480px;
$breakpoint-m: 767px;
$breakpoint-l: 1024px;
$breakpoint-xl: 1280px;

$iphoneSmall: 320px;
$iphone: 375px;
$iphonePlus: 414px;
$ipad: 768px;
$ipadPro: 1024px;
$ipadLandscape: 1366px;
$desktop: 1440px;

$background-transition: background-color 150ms ease-in-out;
$hover-transition: opacity 150ms ease-in-out;
$modal-transition: opacity 150ms ease-in-out;
$border-transition: border-color 150ms ease-in-out;

$box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.03);

$image-opacity: 0.95;
$button-opacity: 0.85;
$overlay-opacity: 0.8;
$text-opacity: 0.6;
$click-opacity: 0.4;
