.contextualbar-component {
  width: 226px;
  height: 100vh;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: $margin * 4;
  padding-right: $margin * 2;
  padding-bottom: $margin * 6.75;
  padding-left: $margin * 2;
  background-color: $lighter-gray;
  transition: 0.5s;

  &.collapsed {
    width: 60px;
  }

  .contextualbar-top {
    &.collapsed {
      .contextualbar-subtitle,
      .contextualbar-top-option-label {
        opacity: 0;
        visibility: hidden;
        transition: visibility 0s linear 150ms, opacity 150ms;
      }
    }

    .contextualbar-subtitle,
    .contextualbar-top-option-label {
      opacity: 1;
      visibility: visible;
      transition: visibility 0s linear 150ms, opacity 250ms;
    }

    .contextualbar-title {
      @include h2;
      margin-bottom: $margin / 4;
      height: 24px;
    }

    .contextualbar-subtitle {
      @include p;
      height: 14px;
      color: $med-gray;
    }

    .contextualbar-top-options {
      margin-top: 130px;

      .contextualbar-top-options-content {
        .contextualbar-top-option {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: $margin * 3;
          text-decoration: none;

          &:first-of-type {
            margin-top: unset;
          }

          &.active {
            .contextualbar-top-option-label {
              color: $black;
            }
          }

          .contextualbar-top-option-icon {
            min-width: 24px;
            margin-right: $margin * 2;
          }

          .contextualbar-top-option-label {
            @include p;
            color: $med-gray;
          }
        }
      }
    }
  }

  .contextualbar-bottom {
    &.collapsed {
      .logout-button {
        opacity: 0;
        visibility: hidden;
        transition: visibility 0s linear 150ms, opacity 150ms;
      }
    }

    .logout-button {
      width: fit-content;
      cursor: pointer;
      opacity: 1;
      visibility: visible;
      transition: visibility 0s linear 150ms, opacity 250ms;

      .contextualbar-bottom-option-label {
        @include p;
      }
    }
  }

  .contextualbar-button-collapse {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    position: absolute;
    bottom: 44px;
    right: -16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: $black;
    z-index: 2;

    .contextualbar-button-collapse-icon {
      color: $white;
    }
  }
}
