.switch-component {
  display: flex;
  align-items: center;

  .switch-label {
    @include label;
    margin-right: $margin * 2;
  }
  
  .switch {
    position: relative;
    display: inline-block;
    min-width: 45px;
    height: 25px;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .slider:before {
        background-color: $black;
      }

      &:checked + .slider:before {
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px);
      }
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $lighter-gray;
      -webkit-transition: .4s;
      transition: .4s;

      &.round {
        border-radius: 34px;
        border-color: $light-gray;
        border-width: 1px;
        border-style: solid;

        &:before {
          border-radius: 50%;
        }
      }

      &:before {
        position: absolute;
        content: "";
        height: 19px;
        width: 19px;
        left: 2px;
        bottom: 2px;
        background-color: $light-gray;
        -webkit-transition: .4s;
        transition: .4s;
      }
    }
  }
}
