.polyglot-scrollbar {
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $black;
    border-radius: 3px;
  }
}

.loader-component {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
