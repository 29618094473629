.project-translations-page {
  @include row;

  .project-translations-content {
    flex: 1;
    background-color: $light-purple;
    @include col___gb(10);
    flex-direction: row;
    display: flex;
    height: 100vh;


    @keyframes fade-in {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @keyframes fade-out {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

    .left-section {
      height: 100vh;
      padding-left: $margin * 4;
      padding-right: 0;
      width: 30%;

      .translations-empty {
        margin-top: $margin * 22.5;
        padding: $margin 0;
        height: calc(100% - 310px);
        border-top: solid 1px $med-gray;
        border-bottom: solid 1px $med-gray;
        box-sizing: border-box;
        text-align: center;
  
        .translations-empty-title {
          @include h3;
        }
      }

      .translations-list {
        @extend .polyglot-scrollbar;
        margin-top: $margin * 22.5;
        padding: $margin 0;
        height: calc(100% - 310px);
        border-top: solid 1px $med-gray;
        border-bottom: solid 1px $med-gray;
        box-sizing: border-box;

        .localized-string-detail {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          padding: $margin;
          margin-top: $margin*2;
          height: 40px;
          color: $med-gray;
          cursor: pointer;

          &.selected {
            color: $black;
          }

          .left {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 50%;

            .localized-string-value {
              @include p;
              width: 100%;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              margin-right: $margin * 3;
            }

            .localized-string-key {
              @include label;
              width: 100%;
              line-height: 40px;
            }
          }

          .localized-string-languages {
            display: flex;
            flex-direction: row;

            .localized-string-language {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 32px;
              height: 24px;
              margin-left: $margin / 2;
              box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.02);
              border-radius: 82px;
              background: $light-gray;

              &:first-of-type {
                margin-left: 0;
              }

              &.translated {
                background: $blue;

                .localized-string-language-label {
                  color: $white;
                }
              }

              .localized-string-language-label {
                @include label;
                color: $blue;
                text-transform: uppercase;
              }
            }
          }
        }
      }

      .localized-string-actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 76px;

        .button-edit-localized-string, .button-add-localized-string {
          cursor: pointer;
          height: 40px;
          background: $lighter-gray;
          box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.02);
          border-radius: 82px;

          .button-text {
            @include p;
            text-align: center;
            color: $blue;
            line-height: 40px;
          }
        }

        .button-edit-localized-string {
          width: 65px;
        }

        .button-add-localized-string {
          width: 156px;
        }
      }
    }

    .right-section {
      // @include col___gb(7);
      max-height: 100vh;
      padding-top: $margin * 5;
      padding-left: $margin * 4;
      padding-right: 0;
      width: 70%;

      .create-localized-string {
        @include row;
        margin-top: $margin * 22.5;

        .create-localized-string-left-section, .create-localized-string-right-section {
          @include col___gb(3);
        }

        .create-localized-string-center-section {
          @include col___gb(6);

          .create-localized-string-title {
            @include p;
            margin-bottom: $margin * 3;
          }

          .form {
            .field {
              margin-bottom: $margin * 2;
            }

            .form-error {
              height: 12px;
              margin-bottom: $margin * 3;
              padding: 0 ($margin * 3 + 1);

              p {
                @include label;
                color: $red;
              }
            }
          }
        }
      }

      .update-localized-string {
        @include row;
        margin-top: $margin * 22.5;

        .update-localized-string-left-section, .update-localized-string-right-section {
          @include col___gb(3);
        }

        .update-localized-string-center-section {
          @include col___gb(6);

          .update-localized-string-title {
            @include p;
            margin-bottom: $margin * 3;
          }

          .form {
            .field {
              margin-bottom: $margin * 2;
            }

            .form-error {
              height: 12px;
              margin-bottom: $margin * 3;
              padding: 0 ($margin * 3 + 1);

              p {
                @include label;
                color: $red;
              }
            }

            .language-dropdowns {
              margin-top: $margin * 10;

              .language-dropdown {
                  margin-top: $margin * 7.5;

                .dropdown-component {
                  margin-bottom: $margin * 7.5;
                }
              }
            }

            .delete-button-container {
              margin-top: $margin * 10;
            }
          }
        }
      }

      .localized-string-values-detail-component {
        margin-top: $margin * 12.5;
        margin-left: $margin * 7;
        height: calc(100% - 229px);
      }

      .localized-string-values-detail {
        @extend .polyglot-scrollbar;
        height: 100%;
        border-top: solid 1px $med-gray;
        border-bottom: solid 1px $med-gray;
        box-sizing: border-box;

        .localized-string-values-detail-content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: calc(100% - 41px);
          max-width: calc(100% / 3 * 3);

          .localized-string-values-source {
            .localized-string-values-source-label {
              @include label;
              color: $med-gray;
              margin-top: $margin * 1.75;
              margin-bottom: $margin * 3;
            }

            .localized-string-values-source-value {
              @include big-p;
              color: $med-gray;
            }
          }

          .value-field {
            display: none;


            .textArea {
              border: transparent;
              background-color: $light-purple;
              width: 99%;
              height: 350px;
              vertical-align: middle;
              @include big-p;
              color: $black;
              border-radius: 10px;
            }

            &.selected {
              display: block;
            }

            .text-field-component {
              font-size: 18px;
              padding-left: 0;
            }
          }

          .localized-string-values-bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .language-arrows {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .arrow {
                cursor: pointer;
                width: 24px;
                height: 24px;
                color: $blue;

                &.left {
                  margin-right: $margin * 1.25;
                }
              }
            }
          }

          .localized-string-values-updated-at {
            margin-bottom: $margin * 1.75;
            .localized-string-values-updated-at-label {
              @include label;
              color: $med-gray;
            }
          }
        }

        .localized-string-values-languages {
          display: flex;
          flex-direction: row;
          height: 41px;
          border-top: solid 1px $med-gray;
          box-sizing: border-box;

          .localized-string-values-language {
            padding: 0 #{$margin * 2};
            border-left: solid 1px $med-gray;
            box-sizing: border-box;
            cursor: pointer;

            &:first-of-type {
              border-left: unset;
            }

            &.selected {
              .localized-string-values-language-label {
                color: $black;
              }
            }

            .localized-string-values-language-label {
              @include p;
              color: $med-gray;
              line-height: 40px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
        }
      }

      .localized-string-value-submit-action {
        display: none;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        &.show {
          display: flex;
        }

        .submit-label {
          @include label;
          color: $med-gray;
          margin-right: $margin * 2;
        }

        &.disabled {
          cursor: not-allowed;
          .button-text {
            color: $med-gray;
          }
        }

        .button-submit-localized-string-values {
          cursor: pointer;
          width: 140px;
          height: 40px;
          background: $black;
          box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.02);
          border-radius: 82px;
          box-sizing: border-box;

          .button-text {
            @include p;
            text-align: center;
            color: $white;
            line-height: 40px;
          }
        }
      }

      .localized-string-value-save-actions {
        display: none;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 37px;
        margin-left: 56px;

        &.show {
          display: flex;
        }

        &.disabled .button-save-localized-string-values .button-text {
          color: $med-gray;
        }

        .switch-component {
          margin-right: $margin * 2;
        }
        .button-save-localized-string-values-gt {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          width: 140px;
          height: 40px;
          background: $lighter-gray;
          box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.02);
          border-radius: 82px;
        }

        .google-translate-img {
          width: 30px;
          height: 30px;
        }

        .action-left {
          display: flex;
        }

        .button-save-localized-string-values {
          cursor: pointer;
          width: 140px;
          height: 40px;
          background: $lighter-gray;
          box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.02);
          border-radius: 82px;

          .button-text {
            @include p;
            text-align: center;
            color: $blue;
            line-height: 40px;
          }
        }
      }
    }
  }

}
