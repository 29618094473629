.project-members-page {
  @include row;

  .project-members-content {
    flex: 1;
    padding: 0 $margin * 5;

    .project-members-list {
      width: 100%;
      margin-top: $margin * 23.5;

      .project-member-detail {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        // padding: 0 #{$margin * 2};
        height: 64px;
        border-bottom: 1px solid $med-gray;

        .project-member-detail-left {
        }

        .project-member-detail-right {
          display: flex;
          flex-direction: row;
          align-items: center;

          .project-member-role {
            margin-right: $margin * 7;
          }

          .project-member-remove {
            cursor: pointer;
            // margin-right: $margin * 7.5;
          }
        }
      }
    }
  }
}
