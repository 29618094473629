@mixin h1 {
  font-family: $font-bold;
  font-weight: normal;
  font-size: 27px;
  line-height: 33px;
}

@mixin h2 {
  font-family: $font-bold;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
}

@mixin h3 {
  font-family: $font-bold;
  font-weight: normal;
  font-size: 14px;
}

@mixin p {
  font-family: $font-regular;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
}

@mixin big-p {
  font-family: $font-regular;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
}

@mixin label {
  font-family: $font-regular;
  font-weight: normal;
  font-size: 11px;
}

@mixin mono {
  font-family: $font-mono;
  font-weight: normal;
  font-size: 14px;
}

body {
  @include p;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  @include mono;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
