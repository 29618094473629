.accept-invitation-page {
  @include row;
  height: 100%;
  background-color: $light-purple;

  .left-section, .right-section, .center-section {
    @include col___gb(4);
  }

  .center-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: $margin * 10 0;

    .logo {
      width: $margin * 7.5;
    }

    .form {
      width: 100%;

      .field {
        margin-bottom: $margin * 2;
      }

      .form-error {
        height: 12px;
        margin-bottom: $margin * 3;
        padding: 0 ($margin * 3 + 1);

        p {
          @include label;
          color: $red;
        }
      }
    }

    .logo-full {
      width: $margin * 25;
    }
  }
}
